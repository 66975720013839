<template>
  <empty-card :title="$t('receipt.settings')">
    <template #title>
      <b-button
        variant="dark"
        size="lg"
        :disabled="loading"
        @click="() => {submit().catch(err => error = err).finally(() => loading = false)}"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-else>
          {{ $t('forms.save') }}
        </span>
      </b-button>
    </template>
    <error-alert :error="error" />
    <validation-observer ref="form">
      <div
        class="d-flex justify-content-center"
      >
        <b-form-group :label="$t('receipt.logo')">
          <template #label>
            {{ $t('receipt.logo') }} <ok-tooltip :text="$t('receipt.prefer-grayscale')" />
          </template>
          <logo-file-input
            ref="receipt-logo-input"
            v-model="form.receipt_logo"
            class="receipt-logo-input"
            @removed="form.remove_receipt_logo = true"
          />
        </b-form-group>
      </div>
      <b-row>
        <template v-if="enabledLocales.length>1">
          <b-col sm="6">
            <ok-text-input
              :label="$t('receipt.print-language')"
              :is-required="true"
            >
              <template #default="props">
                <v-select
                  v-model="form.print_language"
                  :state="props.errors.length > 0 ? false : null"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="printLanguages"
                  :placeholder="$t('receipt.print-language')"
                  label="name"
                  :reduce="t => t.id"
                />
              </template>
            </ok-text-input>

          </b-col>
          <b-col sm="6">
            <ok-text-input
              :label="$t('receipt.main-language')"
              :is-required="true"
            >
              <template #default="props">
                <v-select
                  v-model="form.main_language"
                  :placeholder="$t('receipt.main-language')"
                  :state="props.errors.length > 0 ? false:null"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="localizedEnabledLocalesWithoutMain"
                  label="name"
                  :reduce="t => t.id"
                />
              </template>
            </ok-text-input>
          </b-col>
          <b-col
            v-if="isThereMoreLocales"
            sm="6"
          >
            <ok-text-input
              :label="$t('receipt.localized-language')"
            >
              <template #default="props">
                <v-select
                  v-model="form.localized_language"
                  :placeholder="$t('receipt.localized-language')"
                  :state="props.errors.length > 0 ? false:null"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="enabledLocalesWithoutMain"
                  label="name"
                  :reduce="t => t.id"
                />
              </template>
            </ok-text-input>
          </b-col>
        </template>
        <b-col sm="6">
          <ok-text-input
            v-model="form.receipt_title"
            :placeholder="$t('receipt.receipt-title')"
            :label="$t('receipt.receipt-title')"
            is-required
          />
        </b-col>
        <b-col sm="6">
          <ok-text-input
            v-model="form.receipt_footer"
            :placeholder="$t('receipt.receipt-footer')"
            :label="$t('receipt.receipt-footer')"
            is-required
          />
        </b-col>
        <b-col sm="12">
          <ok-text-input
            :label="$t('receipt.display-qr')"
            hide-label
            :is-required="true"
          >
            <template #default>
              <div style="width: fit-content">
                <ok-switch
                  v-model="form.display_QR"
                  :checkbox="true"
                  :label="$t('receipt.display-qr')"
                />
              </div>
            </template>
          </ok-text-input>
        </b-col>
        <b-col sm="12">
          <ok-text-input
            :label="$t('receipt.display-subtotal')"
            hide-label
            :is-required="true"
          >
            <template #default>
              <div style="width: fit-content">
                <ok-switch
                  v-model="form.display_subtotal"
                  :checkbox="true"
                  :label="$t('receipt.display-subtotal')"
                />
              </div>
            </template>
          </ok-text-input>

        </b-col>
      </b-row>
    </validation-observer>
  </empty-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import LogoFileInput from '@/@bya3/components/inputs/LogoFileInput.vue'
import OkTooltip from '@/@bya3/components/utils/OkTooltip'
import OkSwitch from '@/@bya3/components/inputs/OkSwitch.vue'
import { PrintLanguages } from '@/constants/design-settings-options.ts'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput.vue'
import i18n from '@/libs/i18n'
import PrintAPIs from '@/api/store/print-api'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'

export default {
  name: 'ReceiptSettings',
  components: {
    EmptyCard,
    ErrorAlert,
    BSpinner,
    BButton,
    OkTextInput,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    ValidationObserver,
    LogoFileInput,
    OkTooltip,
    OkSwitch,
  },
  data() {
    return {
      loading: false, // todo: get the form fields from the back-end but the receipt-logo
      receiptLogo: null,
      error: null,
      form: {
        remove_receipt_logo: 0,
        receipt_logo: null,
        print_language: null,
        main_language: null,
        localized_language: null,
        receipt_footer: '',
        receipt_title: '',
        display_QR: true,
        display_cashier_name: true,
        display_subtotal: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      enabledLocales: 'auth/locales',
      receiptSettings: 'auth/printSettings',
    }),
    isThereMoreLocales() {
      return this.enabledLocales.length > 1
    },
    localizedEnabledLocalesWithoutMain() {
      return this.enabledLocales.map(l => ({ name: this.$t(`locales.${l}`), id: l }))
    },
    enabledLocalesWithoutMain() {
      return this.localizedEnabledLocalesWithoutMain.filter(l => l.id !== this.form.main_language)
    },
    printLanguages() {
      return Object.entries(PrintLanguages).map(entry => ({ id: entry[1].id, name: i18n.t(entry[1].name) }))
        .filter(el => this.enabledLocales.length > 1 || (this.enabledLocales.length === 1 && el.id === 1))
    },
  },
  watch: {
    enabledLocales() {
      this.form = {
        ...this.form,
        print_language: this.receiptSettings.print_language ?? 1,
        main_language: this.receiptSettings.main_language && this.enabledLocales.find(lang => lang.id === this.receiptSettings.main_language) ? this.receiptSettings.main_language : null,
        localized_language: this.receiptSettings.localized_language && this.enabledLocales.find(lang => lang.id === this.receiptSettings.localized_language) ? this.receiptSettings.localized_language : null,
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/GET_PRINT_SETTINGS')
    this.form = {
      ...this.form,
      receipt_logo: this.receiptSettings?.receipt_logo ?? '',
      print_language: this.receiptSettings?.print_language ?? 1,
      main_language: this.receiptSettings?.main_language && this.enabledLocales.find(lang => lang === this.receiptSettings.main_language) ? this.receiptSettings.main_language : this.enabledLocales[0] ?? 'ar',
      localized_language: this.receiptSettings?.localized_language && this.enabledLocales.find(lang => lang === this.receiptSettings.localized_language) ? this.receiptSettings.localized_language : this.enabledLocales[0] ?? 'ar',
      receipt_footer: this.receiptSettings?.receipt_footer ?? 'شكراً لك، نراك قريباً',
      receipt_title: this.receiptSettings?.receipt_title ?? 'فاتورة الطلب',
      display_QR: this.receiptSettings?.display_QR ?? true,
      display_subtotal: this.receiptSettings?.display_subtotal ?? true,
    }
  },
  methods: {
    async submit() {
      this.loading = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        throw this.$tc('errors.field-invalid', 2)
      }
      const form = { ...this.form }
      if (!form.receipt_logo || (typeof form.receipt_logo === 'string')) {
        delete form.receipt_logo
      }
      if (!form.remove_receipt_logo) {
        delete form.remove_receipt_logo
      }
      const { setting, message } = await PrintAPIs.process(form)
      await this.$store.commit('auth/SET_PRINT_SETTING', setting)
      this.$notifySuccess(message)
      return true
    },
  },
}
</script>

<style scoped>

</style>
